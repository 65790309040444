import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CatalogForm from './Form';
import CatalogTable from './Table';
import FormText from './Text/FormText';
import LoadingComponent from '../../../Common/Loading/Loading';
import axios from 'axios';

const MULTIPLATFORM_URL = process.env.REACT_APP_MULTIPLATFORM_URL;

const VtexIntegrationTab = () => {
  const { client_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [startUnitaryIntegrations, setStartUnitaryIntegrations] =
    useState(false);
  const [filterBy, setFilterBy] = useState({
    waiting: true,
    error: true,
    success: true,
  });
  const [filterInputValue, setFilterInputValue] = useState('');
  const [textMode, setTextMode] = useState('waiting');
  const [syncTextMode, setSyncTextMode] = useState('');

  const runUnitaryIntegration = async item => {
    try {
      setItems(prevData =>
        prevData.map(prevItem =>
          prevItem.sku === item.sku
            ? { ...prevItem, integrationStatus: 'processing' }
            : prevItem,
        ),
      );

      const response = await axios.get(
        `${MULTIPLATFORM_URL}/${client_id}/integra/${item.sku}/vtex`,
      );

      if (response?.data) {
        const { is_available, price_from, price_to } = response.data;

        setItems(prevData =>
          prevData.map(prevItem =>
            prevItem.sku === item.sku
              ? {
                  ...prevItem,
                  integrationStatus: 'success',
                  productInfo: {
                    ...prevItem.productInfo,
                    isAvailable: is_available,
                    priceFrom: price_from,
                    priceTo: price_to,
                  },
                }
              : prevItem,
          ),
        );
      } else {
        throw new Error('Falha na integração');
      }
    } catch (error) {
      setItems(prevData =>
        prevData.map(prevItem =>
          prevItem.sku === item.sku
            ? {
                ...prevItem,
                integrationStatus: 'error',
                productInfo: { ...prevItem.productInfo, error: error.message },
              }
            : prevItem,
        ),
      );
    }
  };

  const restartSync = () => {
    setIsLoading(false);
    setStartUnitaryIntegrations(false);
    setFilterInputValue('');
    setSyncTextMode('');
    setFilterBy({
      waiting: true,
      error: true,
      success: true,
    });
    setItems([]);
  };

  const beforeUnloadHandler = e => {
    e.preventDefault();
    e.returnValue = '';
    e.returnValue = true;
  };

  useEffect(() => {
    if (syncTextMode === 'batching' || syncTextMode === 'processing') {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [syncTextMode]);

  useEffect(() => {
    if (startUnitaryIntegrations) {
      const runIntegrations = async () => {
        for (const item of items) {
          await runUnitaryIntegration(item);
        }

        setSyncTextMode('finished');
      };

      runIntegrations();
    }
  }, [startUnitaryIntegrations]);

  useEffect(() => {
    const filtered = items.filter(item => {
      return (
        ((filterBy.waiting && !item.integrationStatus) ||
          item.integrationStatus === 'processing' ||
          (filterBy.error && item.integrationStatus === 'error') ||
          (filterBy.success && item.integrationStatus === 'success')) &&
        (item.sku.toLowerCase().includes(filterInputValue.toLowerCase()) ||
          item.product_id
            .toLowerCase()
            .includes(filterInputValue.toLowerCase()) ||
          item.refid.toLowerCase().includes(filterInputValue.toLowerCase()) ||
          item.productInfo.name
            .toLowerCase()
            .includes(filterInputValue.toLowerCase()))
      );
    });

    setFilteredItems(filtered);
  }, [filterBy, items, filterInputValue]);

  return (
    <>
      {!items.length && (
        <>
          <CatalogForm
            isLoading={isLoading}
            setFilteredItems={setFilteredItems}
            setIsLoading={setIsLoading}
            setDataSource={setItems}
            setTextMode={setTextMode}
            setStartUnitaryIntegrations={setStartUnitaryIntegrations}
            setSyncTextMode={setSyncTextMode}
          />

          {!isLoading && <FormText textMode={textMode} />}
        </>
      )}

      {isLoading && <LoadingComponent label={'Buscando itens'} />}

      {items.length > 0 && (
        <CatalogTable
          fullData={items}
          dataSource={filteredItems}
          restartSync={restartSync}
          setFilterBy={setFilterBy}
          setFilterInputValue={setFilterInputValue}
          syncTextMode={syncTextMode}
        />
      )}
    </>
  );
};

export default VtexIntegrationTab;
